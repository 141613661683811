import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import UserContext from "../components/UserContext";
import { useRef, useEffect } from "react";
import dynamic from 'next/dynamic';
import Head from 'next/head';

// Dynamically import Layout to avoid initial load cost
const Layout = dynamic(() => import('../components/layout'), {
    ssr: true,
});

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const pid = router.asPath;
    const scrollRef = useRef({
        scrollPos: 0,
    });

    // Add performance monitoring
    useEffect(() => {
        // Log core web vital metrics
        if (typeof window !== 'undefined') {
            const reportWebVitals = ({ name, delta, value, id }) => {
                console.log(`Metric: ${name}`, delta, value, id);
            };

            // Monitor LCP specifically
            new PerformanceObserver((entryList) => {
                for (const entry of entryList.getEntries()) {
                    console.log('LCP:', entry.startTime, entry);
                }
            }).observe({ type: 'largest-contentful-paint', buffered: true });
        }
    }, []);

    // Set dark mode with priority setting to avoid blocking
    return (
        <>

            <Provider store={store}>
                <ThemeProvider enableSystem={true} attribute="class" defaultTheme="dark">
                    <UserContext.Provider value={{ scrollRef: scrollRef }}>
                        {pid === "/login" ? (
                            <Component {...pageProps} />
                        ) : (
                            <Layout>
                                <Component {...pageProps} />
                            </Layout>
                        )}
                    </UserContext.Provider>
                </ThemeProvider>
            </Provider>
        </>
    );
}

export default MyApp;